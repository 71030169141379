import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Subtitle from '../../components/common/subtitle'
import PageContainer from '../../components/common/page-container'

import awsArchitect from '../../assets/images/about/aws-architect.png'
import awsSysOps from '../../assets/images/about/aws-sysops.png'
import awsDeveloper from '../../assets/images/about/aws-developer.png'
import awsCloud from '../../assets/images/about/aws-cloud.png'
import oracleCloud from '../../assets/images/about/oracle-associate.png'
import azureFundamentals from '../../assets/images/about/azure-fundamentals.png'

export default (props) => (
    <Layout>
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Certifications</Title>
                <Subtitle>Validating our knowledge</Subtitle>
            </TitleContainer>
        </BackgroundContainer>

        <PageContainer>
            <h2>A team that can walk-the-walk</h2>
            <p>Our directors hold a number of industry recognised certifications as evidence of our knowledge and experience. This includes a range of certifications in AWS cloud technologies, as well as in the PHP programming language.</p>
            <p class="text-center">
                <img className="p-4" src={awsArchitect} />
                <img className="p-4" src={awsSysOps} />
                <img className="p-4" src={awsDeveloper} />
                <img className="p-4" src={awsCloud} />
                <img className="p4" src={oracleCloud} />
                <img className="p4" src={azureFundamentals} />
            </p>
            <p><em>These certifications are held by some of our directors individually, validating their expertise. These are not company certifications or evidence of being an organisational partner of cloud providers.</em></p>
        </PageContainer>
    </Layout >
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "about/awards.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
